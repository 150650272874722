import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "./react-auth0-spa";
import Admin from "./Admin";
import { setContext } from "apollo-link-context";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import auth_config from "./auth_config.json";

const apolloCache = new InMemoryCache();
const uploadLink = createUploadLink({
  uri: auth_config.graphQL,
  headers: {
    "keep-alive": "true",
  },
});

const Profile = () => {
  const { isAuthenticated, getTokenSilently, loading, user } = useAuth0();
  const [client, setClient] = useState();

  useEffect(() => {
    const getClient = async () => {
      try {
        if (isAuthenticated) {
          const token = await getTokenSilently();
          const authLink = setContext((_, { headers }) => ({
            headers: {
              ...headers,
              authorization: `Bearer ${token}`,
            },
          }));
          const newClient = new ApolloClient({
            uri: auth_config.graphQL,
            cache: apolloCache,
            link: authLink.concat(uploadLink),
            onError: ({ graphQLErrors, networkError, operation, forward }) => {
              if (networkError) {
                // Handle network errors
              }
            },
          });
          setClient(newClient);
        } else {
          setClient(null); // Set to null if not authenticated
        }
      } catch (error) {
        console.error(error);
      }
    };

    getClient();
  }, [isAuthenticated, getTokenSilently]);

  if (loading || !user || !client) {
    // console.log("Still loading", client);
    return <div>Loading...</div>;
  } else {
    // console.log("Loaded!", client);
    return (
      <ApolloProvider client={client}>
        <Admin />
      </ApolloProvider>
    );
  }
};

export default Profile;
