import "./App.css";
import React, { Suspense, Component, lazy, useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import config from "./auth_config.json";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useAuth0 } from "./react-auth0-spa";
import {
  PaymentsSharp as PaymentsSharpIcon,
  Engineering as EngineeringIcon,
  Folder as FolderIcon,
  AssignmentReturn as AssignmentReturnIcon,
  BusinessCenter as BusinessCenterIcon,
  TextSnippet as StatementsIcon,
} from "@mui/icons-material";
import {
  Menu as MenuIcon,
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon,
  Group as GroupsIcon,
  Forum as ForumIcon,
  Assignment as WorkRequestsIcon,
  Work as ContributionsIcon,
  SettingsApplications as SettingsIcon,
} from "@material-ui/icons";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import displayError from "./screens/ErrorScreen";

const Forum = lazy(() => import("./screens/Forum/Groups"));
const Clients = lazy(() => import("./screens/Clients"));
const Groups = lazy(() => import("./screens/Groups"));
const Employees = lazy(() => import("./screens/Employees"));
const Files = lazy(() => import("./screens/Files/Groups"));
const Suppliers = lazy(() => import("./screens/Suppliers"));
const Entries = lazy(() => import("./screens/Entries/Groups"));
const WorkRequests = lazy(() => import("./screens/WorkRequests/Groups"));
const Contributions = lazy(() => import("./screens/Contributions/Groups"));
const Pay = lazy(() => import("./screens/Pay"));
const Statements = lazy(() => import("./screens/Statements"));
const Configuration = lazy(() => import("./screens/Configuration/Configuration"));

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "", openErrorDialog: true };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error: error });
  }

  render() {
    if (this.state.hasError) {
      return displayError({ errorMessage: this.state.error, actions: false });
    }
    return this.props.children;
  }
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: "hidden",
    maxWidth: "100vw",
  },
}));

function usePersistedState(key, defaultValue) {
  const [state, setState] = useState(() => JSON.parse(localStorage.getItem(key)) || defaultValue);
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}

function Admin() {
  const [component, setComponent] = usePersistedState("component", "Forum");
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user, logout } = useAuth0();
  const location = useLocation();
  const history = useHistory();

  const isClient = user[config.auth0_role_domain].includes("client");
  const isEmployee = user[config.auth0_role_domain].includes("employees");
  const isAdmin = user[config.auth0_role_domain].includes("admin");
  const isSuperAdmin = user[config.auth0_role_domain].includes("super_admin");
  const isSupplier = user[config.auth0_role_domain].includes("supplier");

  const determineUserType = () => {
    if (isSuperAdmin) return "super_admin";
    if (isAdmin) return "admin";
    if (isEmployee) return "employee";
    if (isSupplier) return "supplier";
    if (isClient) return "client";
    return "unknown";
  };

  const userType = (user.userType = determineUserType(user));

  const handleDrawerClick = (name, path) => {
    history.push(path);
    setComponent(name);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [error, setError] = useState("");

  const components = [
    {
      name: "Employees",
      component: Employees,
      path: "/employees",
      icon: <EngineeringIcon />,
      permissions: ["super_admin"],
    },
    {
      name: "Suppliers",
      component: Suppliers,
      path: "/suppliers",
      icon: <BusinessCenterIcon />,
      permissions: ["super_admin"],
    },
    {
      name: "Groups",
      component: Groups,
      path: "/groups",
      icon: <GroupsIcon />,
      permissions: ["super_admin"],
    },
    {
      name: "Clients",
      component: Clients,
      path: "/clients",
      icon: <PersonIcon />,
      permissions: ["super_admin", "admin"],
    },
    {
      name: "Contributions",
      component: Contributions,
      path: "/contributions",
      icon: <ContributionsIcon />,
      permissions: ["super_admin", "admin"],
    },
    {
      name: "Forum",
      component: Forum,
      path: "/forum",
      icon: <ForumIcon />,
      needUser: true,
      permissions: ["super_admin", "admin", "employee", "client"],
    },
    {
      name: "Files",
      component: Files,
      path: "/files",
      icon: <FolderIcon />,
      needUser: true,
      permissions: ["super_admin", "admin", "employee", "client"],
    },
    {
      name: "Entries",
      component: Entries,
      path: "/entries",
      icon: <AssignmentReturnIcon />,
      needUser: true,
      permissions: ["super_admin", "admin", "employee", "client"],
    },
    {
      name: "Report Issues",
      component: WorkRequests,
      path: "/report_issues",
      icon: <WorkRequestsIcon />,
      needUser: true,
    },
    {
      name: "Pay",
      component: Pay,
      path: "/pay",
      icon: <PaymentsSharpIcon />,
      needUser: true,
      permissions: ["super_admin", "admin", "employee", "client"],
    },
    /* {
		name: 'Statements',
		component: Statements,
		icon: <StatementsIcon />,
		needUser: true,
		permissions: ['super_admin', 'admin', 'employee', 'client'], }, */
    {
      name: "Configuration",
      component: Configuration,
      path: "/configuration",
      icon: <SettingsIcon />,
      needUser: true,
      permissions: ["super_admin"],
    },
  ];

  // Sync component state with URL change
  useEffect(() => {
    const matchingComponent = components.find(
      (c) => c.path === location.pathname
    );
    if (matchingComponent && component !== matchingComponent.name) {
      setComponent(matchingComponent.name);
    }
  }, [location.pathname]);

  const drawer = (
    <div style={{ textAlign: "center" }}>
      <img style={{ width: "180px", padding: "10px 0px" }} src={config.main_logo} />
      <Divider />
      <List>
        {components.map(
          ({ name, path, icon, permissions }) =>
            (!permissions || permissions.includes(userType)) && (
              <ListItemButton
                key={name}
                selected={location.pathname === path}
                onClick={() => handleDrawerClick(name, path)}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            )
        )}
        <Divider />
        <ListItemButton
          key={"Log Out"}
          selected={component === "Log Out"}
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={"Log Out"} />
        </ListItemButton>
      </List>
      {/* <div style={{ position: "absolute", bottom: "0", width: "100%" }}>
				<Divider />
				<Typography style={{ textAlign: "center", fontSize: "12px", paddingTop: "10px" }}>Developed by:</Typography>
				<a href="https://neuralai.mt/" target="_blank">
					<img style={{ maxWidth: "130px", padding: "5px 0px" }} src={"./neural_solutions_logo.png"} />
				</a>
			</div> */}
    </div>
  );

  return (
    <ErrorBoundary>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {components.find((c) => c.path === location.pathname)?.name ||
                "Page Not Found"}
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {drawer}
            </Drawer>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Box>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/">
                <Redirect
                  to={
                    components.find((c) => c.name === component)?.path ||
                    "/forum"
                  }
                />
              </Route>
              {components.map(
                ({ name, path, permissions, component: Component, needUser }) =>
                  (!permissions || permissions.includes(userType)) && (
                    <Route key={name} path={path} exact>
                      {needUser ? <Component user={user} /> : <Component />}
                    </Route>
                  )
              )}
              {/* Catch-all Route - currently redirects to forum, could be used to redirect to 404 page not found */}
              <Route path="*">
                <Redirect to="/forum" />
              </Route>
            </Switch>
          </Suspense>
          {component === "Log Out" && alert("Log out")}
        </main>

        {displayError({ errorMessage: error, setError })}
      </div>
    </ErrorBoundary>
  );
}

export default Admin;
